$font-size: calc(0.8rem + 0.3vw) !default;
$font-weight: 400 !default;
$heading-weight: 600 !default;
$line-height: 1.6 !default;
$link-color: #004080 !default;
$dark: #001a33 !default;
$light: #ffffff !default;

@function reduce ($percent) {
  @return rgba(mix($dark, $light), $percent / 100); }
