@import "index", "font", "basic", "layout";

@media screen {
  body {
    margin: 2.5em auto;
    max-width: calc(24% + 24em); }

  body > header, body > article, body > footer {
    margin: 2em 1em; }

  article:not(:last-child) {
    padding-bottom: 2em; } }
